.body {
  width: 100%;
  display: flex;
  flex-direction: column;

  .subtitle {
    color: #000;
    font-family: 'Red Hat Display';
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid;
    border-radius: 1em;
    border-color: #e4e4e4;
    height: 58px;
    padding: 22px 18px;
    margin: 0.375em 0em;
    gap: 10px;

    .text {
      color: #000;
      font-family: 'Manrope';
      font-size: 14px;
      font-weight: 500;
      line-height: 13.369px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.75em;
      width: 1.75em;
      border-radius: 10px;
    }
  }
}

.emptyScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  .imageContent {
    display: flex;
    flex-direction: column;
    gap: 2.25em;
    .description {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Manrope';
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0px;
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      margin-top: 7em;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  .tyc {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #adadad;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .tycLink {
      color: #169dff;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .btn {
    width: 100%;
    height: fit-content;
    background-color: white;
    padding-top: 0.5em;
    box-shadow: 0 -10px 10px white;
    z-index: 10;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
  width: 100%;

  .codeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    border-radius: 16px;
    border: 1px dashed;
    padding: 1.375em;
    margin-top: 1.375em;

    span {
      font-family: 'Manrope';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 13.369px;
    }
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}
