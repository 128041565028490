.subtitle {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  align-self: flex-start;
  color: #000000;
}

.item {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 0.75em;

  font-family: 'Manrope';
  font-style: normal;

  label {
    font-weight: 400;
    line-height: 16px;
    font-size: 0.75em;

    color: #000000;
  }

  span {
    width: 90%;
    overflow-wrap: break-word;
    font-weight: 500;
    line-height: 22px;
    font-size: 1em;

    color: #000000;
    padding-bottom: 0.75em;
  }
}

.copyableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .clipboardBtn:hover {
    cursor: pointer;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  width: 100%;
}
