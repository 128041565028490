.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8em 6em 20em;
  gap: 1em;
  max-height: 30em;

  .subtitle {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
  }

  .description {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
  }

  .illustration {
    display: flex;
    justify-content: center;
    height: stretch;
    margin: 0 -1.25em;
  }
}
