.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5em;

  .subtitle {
    color: #000;
    font-family: 'Red Hat Display';
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
  }
  .subtitleGreen {
    color: #52995d;
    font-family: 'Red Hat Display';
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
  }
  .suffixDescription {
    color: #4a865b;
    font-family: 'Manrope';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .statisticContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid;
    border-color: #e4e4e4;
    margin: 0.375em 0em;
    margin-top: 1.3em;
    gap: 10px;
  }
  .benefits {
    font-family: 'Manrope';
    font-size: 1em;
    font-weight: 500;
    margin-top: 0.5em;
    border-bottom: 1px solid;
    border-color: #e4e4e4;

    .benefitsList {
      padding-inline-start: 1em;
      font-family: 'Manrope';
      font-size: 12px;
      font-weight: 400;
      text-align: left;

      .benefit {
        margin-bottom: 0.5em;
      }
    }
  }
  .statisticDescription {
    font-family: 'Manrope';
    color: #169dff;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  .statisticSuffixDescription {
    font-family: 'Manrope';
    color: #4a865b;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .support {
    color: #cecece;
    text-align: center;
    font-family: 'Manrope';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .termsYCondsLink {
      color: #169dff;
      font-family: 'Manrope';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}
