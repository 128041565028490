.detailsModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.installmentNum {
  margin-bottom: 1em;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-size: 1.125em;

  text-align: center;

  color: #2b2b2b;
}

.installmentAmount {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.045em;
  font-size: 1.875em;

  color: #2b2b2b;
}

.toPay {
  margin-bottom: 2em;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  font-size: 0.75em;

  color: #6a6a6a;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.125em;
  margin-bottom: 2em;

  & > .title {
    margin-bottom: 0.375em;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    font-size: 1.125em;

    color: #000000;
  }

  & > .detailItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > label {
      flex: 5;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      font-size: 1em;

      color: #000000;
    }

    & > .values {
      flex: 2;

      & > span {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        font-size: 1em;

        color: #979797;
      }
    }
  }
}
