.contentTitle {
  padding-right: 5em;

  & > .title {
    /* Font */
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 29px;

    /* Size */
    font-size: 1.5em;

    /* Color */
    color: #000000;

    & > .amount {
      color: #52995d;
    }
  }
}

.feesContainer {
  display: flex;
  gap: 1em;
  padding-left: calc(44px + 1em);

  & > .fee {
    display: flex;
    flex-direction: column;

    & > .feeTitle {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      font-size: 0.75em;
      text-transform: uppercase;

      color: #000000;
      opacity: 0.4;
    }

    & > .feeValue {
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      font-size: 1em;
      text-transform: uppercase;

      color: #000000;
      opacity: 0.4;
    }
  }
}

.progressDescription {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 0.75em;

  color: #000000;
}

.suffixTitle {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & > .returned {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    font-size: 1em;

    color: #000000;
  }

  & > .toReturn {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 0.675em;

    color: #888888;
  }
}
