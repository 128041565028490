@import '../../styles/variables.scss';

.header {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  position: fixed;
  top: 1.25em;
  left: 1.25em;
  right: 1.25em;

  .texts {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    .title {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      font-size: 1.375em;

      color: #000000;
    }

    .subtitle {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      font-size: 0.875em;

      color: #000000;
    }
  }
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1em;

  .cardTitle {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    font-size: 0.875em;

    color: #000000;
    opacity: 0.5;
  }

  .amount {
    display: flex;
    align-items: flex-end;

    .sign {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      font-size: 1.5em;

      color: #000000;

      .earning {
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 500;
        line-height: 128.3%;
        font-size: 1.5em;

        color: #000000;
      }
    }
  }

  .iconContainer {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .segmented {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 1.25em;
    padding-top: 2em;
    padding-bottom: 0.25em;
    background-color: #f2f2f2;
    box-shadow: 0 10px 10px #f2f2f2;
    margin-top: -1em;
  }
}

.action {
  display: flex;
  align-items: center;
  gap: 0.5em;

  .actionText {
    color: $color_primary;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
  }
}

.modalTitle {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  font-size: 1.5em;

  text-align: center;
  color: #000000;
  margin-bottom: 0.5em;
}
