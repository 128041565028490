.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.375em;

  .subtitle {
    width: 70%;
    color: #000;
    font-size: 1.5em;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
  }

  .cvuItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    width: 100%;
    margin-top: 14px;

    font-family: "Manrope";
    font-style: normal;

    label {
      font-family: "Manrope";
      color: #000;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }

    span {
      font-family: "Manrope";
      font-weight: 500;
      line-height: 22px;
      font-size: 1em;

      color: #000000;
      padding-bottom: 12px;
    }
  }
}
