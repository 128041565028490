.subtitle {
  align-self: flex-start;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  color: #000000;
}

.options {
  width: 100%;
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  gap: 0.75em;

  .opLabel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dues {
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      font-size: 1.25em;

      color: #000000;
    }

    .of {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      font-size: 0.875em;

      color: #000000;
      opacity: 0.4;
    }

    .sign {
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      font-size: 18px;

      color: #000000;

      .amount {
        font-size: 20px;
        line-height: 22px;
      }

      .cents {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 0.75em;

    span {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      color: #000000;
    }

    .regulatorySection {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fees {
        display: flex;
        align-items: center;
        gap: 0.75em;

        .fee {
          display: flex;
          flex-direction: column;

          .type {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
          }

          .amountFee {
            font-family: "Red Hat Display";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;

            text-transform: uppercase;
            color: #000000;
          }
        }
      }
    }
  }
}
