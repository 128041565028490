.subtitle {
  width: 90%;
  align-self: flex-start;
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 1.5em;

  color: #000000;
}

.body {
  width: 100%;

  .amount {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: #e83560;

    display: flex;
    align-items: center;

    &.received {
      color: #4a865b;
    }

    .sign {
      font-size: 22px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    margin-top: 0.75em;
    padding-bottom: 0.75em;
    font-family: 'Manrope';
    font-style: normal;
    color: #000000;

    label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  width: 100%;
}
