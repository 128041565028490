.body {
  width: calc(100% + 2.5em);
  position: relative;
  margin-top: 1em;

  &::before {
    content: '';
    height: 3em;
    background: linear-gradient(
      178.29deg,
      rgba(255, 255, 255, 0) 1.45%,
      #ffffff 70.67%
    );
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .topShadow {
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    height: 8px;
    background-color: white;
    box-shadow: 0 8px 8px 8px white;
    z-index: 1;
  }
}
