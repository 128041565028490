@import '../../styles/variables';

.bgTop {
  height: 13em;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;

  border-radius: 0 0 1em 1em;
  background-color: $color_primary;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 2em;

  .profile {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 0.75em;

    .imgContainer {
      width: 2.75em;
      height: 2.75em;
      border: 2px solid rgba(254, 254, 254, 0.4);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      .clipPath {
        width: 36px;
        height: 36px;
        clip-path: circle(50% at 50% 50%);

        img {
          object-fit: cover;
        }
      }
    }

    .greeting {
      display: flex;
      align-items: center;
      gap: 0.25em;

      span {
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-size: 1.125em;

        color: #ffffff;
      }
    }
  }

  .buttonsSection {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .container {
    display: flex;
    justify-content: space-between;
    height: 36px;
    gap: 10px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 10px;
    background-color: #ffffff33;
    color: white;
    z-index: 1;

    font-size: 12px;
    font-weight: 500;
  }
}

.cards {
  width: 100%;
  border: 1px solid $color_border;
  border-radius: 1em;
}
.screen {
  height: 100vh;
  width: calc(100% - 2.5em);
  padding: 0 1.25em;
  position: relative;

  background-color: $color_background__gray__light;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
