.subtitle {
  align-self: flex-start;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  color: #000000;
}

.input {
  align-self: flex-start;
  width: 100%;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
