$color_primary: #ff6130;
$color_secondary: #374bff;

$color_success: #4a865b;
$color_error: #d3375c;
$color_yellow: #ffa523;

$color_background: #f4f4f4;
$color_background__light: #ffffff;
$color_background__gray__light: #f2f2f2;
$color_background__yellow: #fff5db;

$color_border: #bcbcbc66;

$color_text: #000000;
$color_text__light: #ffffff;
$color_text__gray: #bcbcbc;
$color_text__disabled: #bcbcbc66;
$color_text_success: $color_success;
$color_text_error: $color_error;
$color_text_yellow: #996e00;
