.subtitle {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  align-self: flex-start;
  color: #000000;
}

.input {
  align-self: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.modal {
  width: calc(100%);
  display: flex;
  flex-direction: column;
}
