.subtitle {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  align-self: flex-start;
  color: #000000;
  width: 50%;

  .amount {
    color: #4a865b;
  }
}

.statisticAmount {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  color: #000000;
}

.illustration {
  position: absolute;
  top: -3.25em;
  right: -1.25em;
  z-index: 10;
}

.listTitle {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #000000;
}

.listDescription {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #000000;
}
