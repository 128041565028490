@import '../../../styles/variables.scss';

.empty {
  display: flex;
  flex-direction: column;
  gap: 1em;

  width: 100%;
  height: calc(100vh - 80px - 1.75em);

  .description {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    font-size: 1.25em;

    text-align: center;
    color: #000000;
  }

  .illustration {
    margin-top: 10em;

    svg {
      margin-left: -1.25em;
    }
  }

  .reloadSection {
    margin-top: 10em;
    align-self: center;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25em;

    .action {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }

    .reloadText {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;

      color: #000000;
      opacity: 0.3;

      &.action {
        color: $color_primary;
        opacity: 1;
      }
    }
  }
}

.filter {
  background-color: white;
  position: fixed;
  z-index: 10;
  top: 68px;
  left: 0;
  right: 0;
  padding: 1em;
  padding-bottom: 0;
  box-shadow: 0 15px 15px white;
  width: 100%;
}

.body {
  width: 100%;

  &::before {
    content: '';
    height: 3em;
    background: linear-gradient(
      178.29deg,
      rgba(255, 255, 255, 0) 1.45%,
      #ffffff 70.67%
    );
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .statisticSuffix {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    font-size: 1em;

    color: #e83560;

    display: flex;
    align-items: center;

    &.received {
      color: #4a865b;
    }

    .sign {
      font-size: 1.25em;
    }

    .cents {
      font-size: 10px;
      line-height: 13px;
    }
  }
}
