@import '../../styles/variables';

.content {
  /* Display */
  position: relative;

  /* Size */
  width: 100%;
}

.subtitle {
  /* Color */
  color: #000;

  /* Size */
  font-size: 1.5em;

  /* Margin */
  margin-top: 0;
  margin-bottom: 0.375em;

  /* Font */
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 1.25em;
  width: 70%;
}

.appsSection {
  /* Display */
  display: flex;
  flex-direction: column;

  /* Size */
  width: 100%;
}

.sectionTitle {
  /* Color */
  color: #000;

  /* Size */
  font-size: 0.75em;

  /* Margin */
  margin: 0.75em 0;

  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 1.75em;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.4;
}

.btnContainer {
  margin-bottom: 0.625em;
}

.uiMessage {
  position: fixed;
  bottom: 2em;
  left: 1.25em;
  right: 1.25em;
}

.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;

  section {
    margin-top: -1em;
  }

  .modalTitle {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    text-align: center;

    color: #000000;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
