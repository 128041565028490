.filter {
  background-color: white;
  position: fixed;
  z-index: 10;
  top: 4.5em;
  left: 0;
  right: 0;
  padding: 1em;
  padding-bottom: 0;
  box-shadow: 0 20px 15px white;
}

.installmentsList {
  z-index: 0;
  width: 100%;
}

.filterModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .filterTitle {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    font-size: 1.25em;

    text-align: center;

    color: #000000;

    margin-bottom: 0.5em;
  }
}
