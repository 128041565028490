.title {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: normal;
  color: #000000;
  margin-top: 1em;
}

.container {
  width: 60%;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  opacity: 0.4;
  margin-top: -0.5em;
}

.backgroundImg {
  position: absolute;
  top: 1.125em;
  right: 0em;
}
