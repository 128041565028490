.body {
  width: 100%;
  min-height: calc(100vh - 80px - 1.25em);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
}

.iconSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25em;

  & > span {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    font-size: 1.125em;

    color: #2b2b2b;
  }
}

.paymentSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > .paymentChildren {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;

    & > label {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      font-size: 1.875em;

      color: #2b2b2b;

      &.paidAmount {
        opacity: 0.6;
      }
    }

    & > span {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      font-size: 0.75em;

      color: #6a6a6a;
    }
  }
}

.lastPayments {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.subtitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  font-size: 1.125em;

  color: #000000;
}

.detailsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.125em;
  margin-bottom: 2em;

  & > .title {
    margin-bottom: 0.375em;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    font-size: 1.125em;

    color: #000000;
  }

  & > .detailItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > label {
      flex: 5;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      font-size: 1em;

      color: #000000;
    }

    & > .values {
      flex: 2;

      & > span {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        font-size: 1em;

        color: #6a6a6a;
      }
    }
  }
}

.withoutCollections {
  flex: 5;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-size: 1em;

  color: #000000;
  opacity: 0.5;
}
