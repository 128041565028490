.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em; // 16px

  .illustration {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 6em;
    border-bottom: 1px solid black;
  }

  .subtitle {
    color: #000;
    font-family: 'Red Hat Display';
    font-size: 1.75em;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .description {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
  }
}
