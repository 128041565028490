.subtitle {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  align-self: flex-start;
  color: #000000;
}

.body {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  .item {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 0.75em;

    font-family: "Manrope";
    font-style: normal;

    label {
      font-weight: 400;
      line-height: 16px;
      font-size: 0.75em;

      color: #000000;
    }

    span {
      font-weight: 500;
      line-height: 22px;
      font-size: 1em;

      color: #000000;
      padding-bottom: 0.75em;
    }
  }
}
