.subtitle {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 1.5em;

  align-self: flex-start;
  color: #000000;
}

.inputContainer {
  width: 100%;
}

.frequentAccounts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 1em;

  .faTitle {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    font-size: 11px;

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #000000;
    opacity: 0.4;
  }

  .withoutFa {
    margin-top: 0.5em;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #000000;
    opacity: 0.5;
  }
}

.footer {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -10px 10px white;
  height: 5em;
  display: flex;
  align-items: center;
  margin-bottom: -1em;
}
