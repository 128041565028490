.result {
  /* Display */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Size */
  width: 100%;
  height: 100vh;
  padding: 0 1em;
}

.top {
  /* Display */
  flex: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5em;

  /* Size */
  width: 100%;
}

.top label {
  /* Font */
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;

  /* Size */
  font-size: 24px;
  user-select: none;
}

.bottom {
  /* Display */
  flex: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;

  /* Size */
  width: 100%;
  margin-bottom: 1em;
}

.status__success {
  /* Color */
  background-color: #dbfee0;
  color: #4a865b;
}

.status__error {
  /* Color */
  background-color: #ffebeb;
  color: #ff691e;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
