.headerContainer {
  /* Size */
  width: 100%;

  /* Display */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.title {
  /* Color */
  color: #000;

  /* Font */
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 1.75em;

  /* Size */
  font-size: 1.375em;
}

.subtitle {
  /* Color */
  color: #000;

  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 0.625em;

  /* Size */
  font-size: 0.875em;
}

.cardTitle {
  width: 70%;
  /* Color */
  color: #000;

  /* Font */
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;

  /* Size */
  font-size: 1.125em;
}

.imgContainer {
  position: absolute;
  height: 150%;
  top: -0.75em;
  right: -1em;
  overflow: hidden;
}

.textFooter {
  /* Display */
  flex: 3;

  /* Color */
  color: #fff;

  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 15px;

  /* Size */
  font-size: 11px;
}

.allApps {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.listItem {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.75em;
  padding: 0.875em 0px;

  /* Color */
  color: #000;

  &.firsItem {
    padding-top: 0;
  }

  &.lastItem {
    padding-bottom: 0;
  }

  &.listTitle {
    /* Font */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 1.125em;

    /* Size */
    font-size: 0.875em;
  }
}

.whatsappImage {
  position: absolute;
  top: -1em;
  right: -1em;
}

.cardGreyTitle {
  /* Font */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 1.125em;

  /* Size */
  font-size: 0.875em;

  /* Color */
  color: #000000;
  opacity: 0.5;
}

.connectedApps {
  /* Size */
  width: 100%;

  /* Display */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25em;
}

.iconContainer {
  /* Display */
  position: relative;

  /* Size */
  width: 54px;
  height: 54px;
}

.plusCircle {
  /* Size */
  width: 52px;
  height: 52px;

  /* Border */
  border-radius: 50%;
  border: 1px #a4a4a4 solid;

  /* Display */
  display: flex;
  align-items: center;
  justify-content: center;
}

.expiresIcon {
  /* Display */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -0.5em;
  bottom: 0;
  transform: rotate(180deg);

  /* Size */
  width: 1.5em;
  height: 1.5em;

  /* Border */
  border-radius: 50%;

  /* Color */
  background-color: #fff;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.appVersion {
  width: 100%;

  span {
    /* Font */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    line-height: 15px;

    /* Size */
    font-size: 0.75em;

    /* Color */
    color: #000000;
    opacity: 0.5;
  }

  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 1.25em;
    padding-bottom: 1.25em;
  }
}

.statisticsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0.75em;
}

.statisticBox {
  display: flex;
  flex-direction: column;

  /* Size */
  padding: 0.5em;

  /* Border */
  border: 1px solid #e5e5e5;
  border-radius: 0.75em;
}

.statisticHeader {
  display: flex;
  justify-content: space-between;

  & > span {
    /* Font */
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    line-height: 1em;

    /* Size */
    font-size: 1.25em;

    /* Color */
    color: #000000;
  }
}

.statisticFooter {
  width: 60%;

  & > span {
    /* Font */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    line-height: 0px;
    padding-top: 0;

    /* Size */
    font-size: 0.75em;
    margin: 0;

    /* Color */
    color: #000000;
    opacity: 0.4;
  }
}
