.screen {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .bgImg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: fill;
  }

  .video {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: fill;
  }

  .opacity {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 1;
  }

  .logo {
    z-index: 2;
  }

  .footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    padding: 1.25em;
    padding-bottom: 2em;

    border-top-left-radius: 1.25em;
    border-top-right-radius: 1.25em;

    display: flex;
    flex-direction: column;
    gap: 1.25em;

    background-color: white;
    z-index: 2;

    .terms {
      color: #000;
      text-align: center;
      font-family: 'Manrope';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      .termsLink {
        color: #169dff;
        text-decoration: underline;
      }
    }
  }
}
