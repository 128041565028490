.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .subtitle {
    color: #000;
    font-family: 'Red Hat Display';
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;

    .program {
      color: #52995d;
    }
  }

  .withBorderBottom {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-bottom: 1px solid;
    border-color: #e4e4e4;

    .suffixDescription {
      color: #4a865b;
      font-family: 'Manrope';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .benefits {
    font-family: 'Manrope';
    font-size: 1em;
    font-weight: 500;
    margin-top: 0.5em;
    border-bottom: 1px solid;
    border-color: #e4e4e4;

    .benefitsList {
      padding-inline-start: 1em;
      font-family: 'Manrope';
      font-size: 12px;
      font-weight: 400;
      text-align: left;

      .benefit {
        margin-bottom: 0.5em;
      }
    }
  }
}

.modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .title {
    color: #000;
    text-align: center;
    font-family: 'Red Hat Display';
    font-size: 1.5em;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  .description {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    .lostBenefits {
      color: #000;
      font-family: 'Manrope';
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      opacity: 0.20000000298023224;
      text-align: center;
    }

    .checkIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #4a865b;
    }

    .infoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #eab42a;
    }

    .statistics {
      border-bottom: 1px solid #f2f2f2;
    }

    span {
      color: #2e2e2e;
      font-family: 'Manrope';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    .futureCharge {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.25em;

      .date::before {
        content: '\2022';
        width: 1em;
        color: #000;
        display: inline-block;
        font-weight: bold;
        padding-right: 1.5em;
      }

      span {
        color: #000;
        font-family: 'Manrope';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
      }
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75em;

  .expiration {
    color: #adadad;
    text-align: center;
    font-family: 'Manrope';
    font-size: 0.75em;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;

    .date {
      color: #000;
      font-weight: 700;
    }
  }
}
